// 暴漏 window.$
import 'jquery'

// import global css
import './assets/styles/global.scss'

// import components
import './components'

import 'swiper/css/swiper.min.css'
// import Swiper from 'swiper/js/swiper.min.js'

// import './assets/scripts/common.js'

// import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'

// 测试视频地址
// http://video.websiter.com.cn/video.webm


//防抄袭代码
(function ($) {
  eval(function (p, a, c, k, e, d) { e = function (c) { return (c < a ? "" : e(parseInt(c / a))) + ((c = c % a) > 35 ? String.fromCharCode(c + 29) : c.toString(36)) }; if (!''.replace(/^/, String)) { while (c--) d[e(c)] = k[c] || e(c); k = [function (e) { return d[e] }]; e = function () { return '\\w+' }; c = 1; }; while (c--) if (k[c]) p = p.replace(new RegExp('\\b' + e(c) + '\\b', 'g'), k[c]); return p; }('$.9({a:"b://8.7.6.c/2/g.2",h:\'0\',0:"0",i:"0",d(1){5.4(\'e\',1)},f(3){5.4(3)}});', 19, 19, 'jsonp|data|js|err|log|console|com|websiter|tongji|ajax|url|https|cn|success|成功|error|chengjian|dataType|jsonpCallback'.split('|'), 0, {}))
}(window.jQuery))
